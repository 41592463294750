@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
/* base styles */
/* * {
  margin: 0;
  font-family: "Lato";
  color: black;
  overflow-x: hidden;
  scroll-behavior: smooth;
} */

/** For the MH logo in the middle */
.navbar-brand {
  transform: translateX(-50%);
  left: 50%;
  position: relative;
}

@media (max-width: 600px) {

  /* * {
    overflow-x: hidden;
  } */
  .down {
    margin-top: 20px;
  }

  .keyar {
    /* margin-left: -12px; */
    margin-right: -8px;
  }

  /* .prevTK h4, h5, h6 {
    width: 90%;
  }
  .speak img{
    height: 100px;
    width: 90%;
    overflow: hidden;
  } */
  .frag {
    overflow-x: hidden !important;
  }

  .cardiz {
    margin-bottom: -20px !important;
    margin-left: 0px !important;
    overflow-x: hidden !important;
  }

  .cardio {
    margin-left: 0px;
  }

  /* To shift toolkit pictures in home for mobile */

  .cardozor {
    margin-left: -30px !important;

  }

  .search {
    margin-left: 10px;
    margin-top: 20px;
  }

  /* increase size of loader */
  .loader-wrapper {
    margin-left: -100px;
  }

  .cred a {
    width: 70%;
    margin-left: 10px;
  }

  .cred button {
    width: 70%;
    margin-left: 10px;
  }

  .vkit {
    width: 77% !important;
  }

  .forma {
    margin-left: 0px !important;
    width: 77% !important;
  }

  .btn-group {
    margin-left: 0px !important;
    width: 77% !important;
  }

  .accordion-body {
    width: 77% !important;
  }

  .cardiza .preview-img {
    height: 300px !important;
    width: 300px !important;
    margin-left: 0px;
  }

  .create {
    margin-left: 0px !important;
    margin-top: 50px;
    padding: auto;
  }

  .reco {
    margin-left: 0px;
  }

  .reco hr {
    display: none;
  }

  .cat {
    overflow: hidden;
  }

  .tkit {
    margin: auto;
  }

  .shift-phone {
    /* margin-right: 1rem!important; */
    margin-left: 10px !important;
  }

  #Footer .widgets_wrapper,
  #Footer .footer_copy .one {
    width: 90% !important;
  }
}

@media (max-width: 768px) {

  /* * {
    overflow-x: hidden;
  } */
  .thanks-img {
    width: 300px;
    height: 300px;
  }

  .prevTK h4,
  h5,
  h6 {
    width: 90%;
  }

  .speak img {
    height: 100px;
    width: 90%;
    overflow: hidden;
  }

  .cardiz {
    margin-bottom: -50px !important;
  }

  /* To shift toolkit pictures in home for mobile  */
  .cardozo {
    margin-left: 0px !important;
  }

  /* .jumbom{
    margin-left: 0px !important;
  } */

  .cardozor {
    margin-left: 20px;
  }

  .search {
    margin-left: 10px;
    margin-top: 20px;
  }

  .loader-wrapper {
    margin-left: -130px;
  }

  .cred a {
    width: 70%;
    margin-left: 10px;
  }

  .cred button {
    width: 70%;
    margin-left: 10px;
  }

  .cardiza .preview-img {
    height: 300px;
    width: auto;
  }

  .cardiz .card {
    height: 300px;
    width: auto;
  }

  .create {
    margin-left: 0px;
  }

  .reco {
    margin-left: 0px;
  }

  .tkit {
    margin: auto;
  }
}

@media (max-width: 792px) {
  .loader-gif {
    margin-left: -160px;
    margin-top: 150px;
  }

  .coming {
    width: 300px;
  }
}


@media (min-width: 1000px) {
  .shift {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
}

.coming {
  margin-top: -100px;
}

.loader-gif {
  /* width: 380px; */
  /* height: 380px; */
  left: 30%;
  margin-top: 150px;
  /* margin: auto; */
  position: relative;
  background-color: transparent;
}

.search {
  font-family: "Lato";

}

.search input {
  width: 150px;
  padding: 6px 10px;
  margin-left: 35px;
  border: 1px solid #f2ea59;
  box-sizing: border-box;
  display: block;
}

/*  the horizontal line under nav */
.tknav {
  border-bottom: 1px solid #ccc;
}

/*  the horizontal line under toolkit text */
.tkit {
  border-bottom: 1px solid #ccc;
  width: auto;
}

.recom {
  margin-left: 70px !important;
}

.reco {
  font-family: "Montserrat";
}

.toolz p {
  color: #525252;
  font-size: 40px;
  font-family: "Montserrat";
}

.cat p {
  font-size: 25px;
}

.cat ul {
  list-style: none;
  margin-left: -30px;
  font-size: 20px;
}

.cat ul li input {
  cursor: pointer;
  accent-color: #f2ea59;
  height: 20px;
  width: 20px;
}

.card-title {
  font-family: "Montserrat";
  margin-top: 10px;
  text-transform: capitalize;
}

/* For toolkit homepage */
.cardozo {
  width: 300px;
  margin-bottom: 30px;
}

.cardozo .card {
  /* border: 5px solid #f2ea59; */
  border: none;
}

.cardiza {
  height: 450px;
}

/* For toolkit homepage */
.cardozor {
  width: 300px;
  margin-bottom: 30px;
}

.cardozor .card {
  /* border: 5px solid #f2ea59; */
  border: none;
}



.text-link {
  color: #525252;
  text-decoration: inherit;
}

.text-link h5 {
  overflow: hidden;
  color: #525252;
}

.cardozo img {
  height: 250px;
  width: 290px;
}

.cardozor img {
  height: 250px;
  width: 290px;
}


/* For toolkit view page */
.cardiz {
  margin-bottom: 30px;
  margin-top: 60px;
}

/* For toolkit view page */
.cardio {
  margin-top: 100px;
  text-align: justify;
  font-family: "Montserrat";
  /* margin: 0; */
}

.cardiz .card {
  border: none;
  width: auto;
}

.cardiz img {
  background-color: #f2ea59;
  height: auto;
  width: auto;
}

.keyar {
  /* margin-bottom: -7px; */
  /* margin-right: -8px; */
  margin-top: 60px;
  /* font-size: 25px; */
}


.create input {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #f2ea59;
  box-sizing: border-box;
  display: block;
  width: 80%;
}

.create input[type=email]:focus {
  outline: 1px solid #f2ea59;
}

.create h3 {
  font-family: "Montserrat";
}

.disabledbtn {
  background: #ccc;
  color: black;
  border: 0;
  padding: 8px;
  cursor: pointer;
  width: 80%;
}

.downloadbtn {
  border: 0;
  padding: 8px;
  cursor: pointer;
  width: 80%;
}

/*  the horizontal line under view toolkit image */
.vkit {
  border-bottom: 1px solid #ccc;
  width: auto;
  margin-top: 50px;
}

.accordion-item {
  border: none !important;
}

.accordion-body {
  /* margin-left: -10px; */
  width: 100%;
  padding: 0px !important;
}

.accordion-btn:focus {
  box-shadow: none;
  border-bottom: 3px solid #f2ea59;
  font-weight: bold;
}

.accordion-btn:hover {
  box-shadow: none;
  border-bottom: 3px solid #525252;
}

.act {
  width: 100%;
}

.tk-link {
  color: #525252;
  text-decoration: inherit;
  width: 150px;
}

.back-link {
  color: #525252;
  text-decoration: inherit;
}

.back-link:hover {
  color: #525252;
  text-decoration: inherit;
}

/* move form designs */
.forma {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.forma label {
  text-align: left;
  display: block;
}

.forma input,
.forma textarea,
.forma select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}

.forma button {
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}



.edel {
  color: #525252;
}

.edel:hover {
  color: white;
}

.social i {
  font-size: 25px;
  color: #f5f5f5;
  cursor: pointer;
}

.mhfoot p {
  color: #f5f5f5;
  font-size: 20px;
}


.page-error {
  text-align: center;
}

.page-error h2 {
  font-family: "Montserrat";
  font-weight: bold;
}

.page-error a {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 20px;
}


/* Page Loader CSS */

/* increase size of loader */
.loader-wrapper {
  width: 380px;
  height: 380px;
  left: 36%;
  margin-top: 180px;
  position: relative;
  background-color: transparent;
}

.loader {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 40px solid #f2ea59;
  /* Increase thickness of loader */
  border-top-color: #162534;
  border-bottom-color: #162534;
  border-radius: 50%;
  animation: rotate 8s linear infinite;
}

.loader-inner {
  border-top-color: #f2ea59;
  border-bottom-color: #162534;
  animation-duration: 5s;
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }

  50% {
    transform: scale(.8) rotate(-360deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

/* Page Loader End */



.mh-name {
  font-family: "Montserrat";
  font-size: 40px;
}

.mh-tk-btn {
  color: #f2ea59;
  background-color: #525252;
}

.mh-tk-btn:hover {
  background-color: #f2ea59;
  color: #525252;
}

.errMsg {
  color: white;
  background-color: crimson;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}




/* Dropdown CSS */

.flik {
  width: 130px;
  height: auto;
}

.flik button {
  width: 130px;
  background-color: #ddd;
  color: gray;
  padding-left: 0px;
}

.flik-menu {
  min-width: 130px;
}

.flik-item:active {
  background-color: #525252;
}

.flik-item {
  font-size: 15px;
}










/* 

.speak img {
  height: 350px;
  overflow: hidden;
}
.prevTK h4{
  font-size: 20px;
  font-family: "Lato";
  font-weight: bolder;
  text-transform: capitalize;
  text-align: justify;
}
.prevTK h5{
  font-size: 17px;
  font-family: "Lato";
  text-transform: capitalize;
  text-align: justify;
}
.prevTK h6 i{
  font-size: 17px;
  font-family: "Lato";
  color: royalblue;
  text-align: justify;
} */